import React, { useState,useEffect } from "react";
import axios from "axios";

const ProductSubCategoryManager = () => {
      const [productCategory, setProductCategory] = useState();
      const [productSubCategory, setProductSubCategory] = useState('');
      const [categories, setCategories] = useState([]);
      const [subCategories, setSubCategories] = useState([]);
      const [newSubCategory, setNewSubCategory] = useState('');
    
//   const [productCategory, setProductCategory] = useState("");
//   const [productSubCategory, setProductSubCategory] = useState("");
//   const [newSubCategory, setNewSubCategory] = useState("");
//   const [categories, setCategories] = useState([]); // Example: Fetch categories from an API or a static list
//   const [subCategories, setSubCategories] = useState([]); // Example: Fetch sub-categories based on the selected category

//   const addSubCategory = () => {
//     if (!newSubCategory.trim()) {
//       alert("Sub-category cannot be empty!");
//       return;
//     }

//     // Example logic to add a new sub-category
//     setSubCategories([...subCategories, newSubCategory]);
//     setNewSubCategory("");
//     alert("Sub-category added successfully!");
//   };
const addSubCategory = async () => {
    try {
      if (newSubCategory) {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/addsubcategory`, {
          category: productCategory,
          subcategory: newSubCategory,
        });
        const updatedCategory = response.data;

        setCategories((prev) =>
          prev.map((category) =>
            category._id === updatedCategory._id ? updatedCategory : category
          )
        );
        setNewSubCategory('')

      }
    } catch (error) {
      console.error('There was an error posting the love letter!', error);
    }
  };

  useEffect(() => {

    const getcategories = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getcategory`)

            // console.log(data.data);
            setCategories(data.data);
        }
        catch (error) { console.log('There was an error getting the Categories', error); }
    }

    getcategories();

}, [])

  useEffect(() => {
    const category = categories.filter(c => c.category === productCategory)
    // console.log(category)
    const subcategories = category[0]?.subcategories
    // console.log(subcategories)
    if (subcategories) {
      setSubCategories(subcategories)
    }
  }, [productCategory, categories])

  return (
    <div>
      <div className="font-subheading text-3xl mb-6">Add Product Sub-Category</div>
      <div className="">
        <div className="flex gap-[5%] w-full">
          <div className="w-full">
            <div>Select a Category</div>
            <select
              className="input formInput"
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.category} value={category.category}>
                  {category.category}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full">
            <div>Existing Sub-Categories</div>
            <select
              className="input formInput"
              value={productSubCategory}
              onChange={(e) => setProductSubCategory(e.target.value)}
            >
              <option value="">Select a sub-category</option>
              {subCategories.map((subCategory, index) => (
                <option key={index} value={subCategory}>
                  {subCategory}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-4">
          <div>Add Product Sub-Category</div>
          <input
            className="input formInput"
            type="text"
            placeholder="Sub-category"
            value={newSubCategory}
            onChange={(e) => setNewSubCategory(e.target.value)}
          />
          <button onClick={addSubCategory}>Add Sub-Category</button>
        </div>
      </div>
    </div>
  );
};

export default ProductSubCategoryManager;
