import axios from 'axios'
import React from 'react'
import { useState, useEffect } from 'react'


const AddEditProds = () => {
    const [datafromDB, SetdatafromDB] = useState()
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allProducts, setAllProducts] = useState()
    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState('');
    const [imageBoxCount, setImageBoxCount] = useState(1);

    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [file3, setFile3] = useState();
    const [file4, setFile4] = useState();
    const [preview, setPreview] = useState(null);
    const [selectedProductID, setSelectedProductID] = useState()
    const addimageboxes = () => {
        if (imageBoxCount < 4) {
            setImageBoxCount(imageBoxCount + 1);
        }

    };



    const handleViewClick = (product) => {
        console.log(product)
        setSelectedProduct(product);
        setIsModalOpen(true);
        document.body.classList.add('overflow-hidden');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProduct(null);
        document.body.classList.remove('overflow-hidden');
    };

    // Close modal on click outside
    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            closeModal();
        }
    };
    // 
    const [groupedProducts, setGroupedProducts] = useState({});

    useEffect(() => {

        const getproducts = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)
                SetdatafromDB(data)
                // console.log(data.data);
                // setFilteredProducts(data.data);
                setAllProducts(data.data);
                console.log(data.data)
            }
            catch (error) { console.log('There was an error getting the products!', error); }
        }

        getproducts();

    }, [])

    useEffect(() => {
        const groupByCategory = (products) => {
            if (!Array.isArray(products)) {
                return {}; // Return an empty object if products is not an array
            }

            return products.reduce((acc, product) => {
                acc[product.category] = acc[product.category] || [];
                acc[product.category].push(product);
                return acc;
            }, {});
        };


        setGroupedProducts(groupByCategory(allProducts));
    }, [allProducts]); // Runs whenever allProducts changes


    // Step 1: State Variables for each field
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [code, setCode] = useState('');
    const [customPrices, setCustomPrices] = useState({});
    const [goldWeight, setGoldWeight] = useState('');
    const [diamondWeight, setDiamondWeight] = useState('');
    const [options, setOptions] = useState([]);

    // Load selectedProduct data into state
    useEffect(() => {
        if (selectedProduct) {
            setName(selectedProduct.name);
            setCategory(selectedProduct.category);
            setSubCategory(selectedProduct.subCategory);
            setCode(selectedProduct.code);
            setCustomPrices(selectedProduct.customPrices);
            setGoldWeight(selectedProduct.goldWeight);
            setDiamondWeight(selectedProduct.diamondWeight);
            setOptions(selectedProduct.options);
        }
        console.log(selectedProduct, 'selectedProduct')
    }, [selectedProduct]);

    // Step 2: Update nested customPrices data
    const handleCustomPriceChange = (e, type, index) => {
        const updatedPrice = e.target.value;

        // Update the selectedProduct state
        setSelectedProduct((prevProduct) => {
            const updatedCustomPrices = { ...prevProduct.customPrices };
            updatedCustomPrices[type][index].price = updatedPrice;

            return { ...prevProduct, customPrices: updatedCustomPrices };
        });
    };

    // Step 3: Handle Save Button - Axios Call
    const handleSave = async () => {
        const updatedProduct = {
            _id: selectedProduct._id,
            about: selectedProduct.about,
            imageURLs: selectedProduct.imageURLs,
            name,
            category,
            subCategory,
            code,
            customPrices,
            goldWeight,
            diamondWeight,
            options,
        };

        console.log(updatedProduct)
        try {
            const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/editproduct`, { updatedProduct });


            if (data.data) {
                closeModal();
            }

        }
        catch (err) { console.log(err); }
    };

    // State variables for the new image modal
    const [viewImgModalOpen, setViewImgModalOpen] = useState(false);
    const [selectedViewImgData, setSelectedViewImgData] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const [newViewImgURL, setNewViewImgURL] = useState("");

    // Function to open the image modal
    const handleViewImgClick = (product) => {
        console.log(product)
        setSelectedProductID(product._id)
        const length = product.imageURLs.length
        setImageBoxCount(4 - length)
        setSelectedViewImgData(product.imageURLs || []);
        setSelectedName(product.name);
        setViewImgModalOpen(true);
    };

    // Function to close the image modal
    const closeViewImgModal = () => {
        setViewImgModalOpen(false);
        setNewViewImgURL("");
    };

    // Function to delete an image from the list
    const handleViewImgDelete = async (index) => {


        const updatedImages = [...selectedViewImgData];
        updatedImages.splice(index, 1);
        console.log(updatedImages)



        try {
            //  await axios.post('/deleteletter', { id });

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/deleteimage`, {
                updatedImages, _id: selectedProductID
            });

            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error posting ID to deleteletter:', error);

        }
        setSelectedViewImgData(updatedImages);
    };



    // Function to add a new image
    const handleAddViewImg = () => {
        if (newViewImgURL.trim()) {
            setSelectedViewImgData([
                ...selectedViewImgData,
                { url: newViewImgURL, fileName: newViewImgURL.split('/').pop() }
            ]);
            setNewViewImgURL("");
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }

        if (isModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }


        // Cleanup function to reset the overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    useEffect(() => {
        if (viewImgModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }

        if (viewImgModalOpen) {
            // Disable background scrolling
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable background scrolling
            document.body.style.overflow = 'auto';
        }


        // Cleanup function to reset the overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [viewImgModalOpen]);

    const uploadPhoto = async (e) => {

        e.preventDefault();
        console.log(selectedProductID)

        const formData = new FormData();

        formData.append("image", file1);
        formData.append("_id", selectedProductID);
        try {
            const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/addimage`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })


            if (data.data.message = "Image added successfully") {
                window.alert("Image added successfully!");
                setFile1('')
            }

        }
        catch (err) { console.log(err); }

    }


    const DeleteProduct = async (product) => {
        console.log('delete product')
        console.log(selectedProduct)
        const id = selectedProduct._id

        console.log(id)
        // ("_id", selectedProductID);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/deleteproduct`, { id });


            if (response.data) {
                window.location.reload();
            }

        }
        catch (err) { console.log(err); }



    }


    const [newOption, setNewOption] = useState(''); // To store new option input
    const [isAddingOption, setIsAddingOption] = useState(false); // T


    // Delete product option function
    const deleteProductOption = async (option) => {
        // Step 1: Remove the selected option from the options array
        const updatedOptions = selectedProduct.options.filter(opt => opt !== option);

        // Step 2: Update the state with the new selectedProduct
        const updatedProduct = { ...selectedProduct, options: updatedOptions };
        setSelectedProduct(updatedProduct);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/updatecut`, updatedProduct, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Step 4: Handle the successful response
            if (response.status === 200) {
                console.log('Product updated successfully');
            } else {
                throw new Error('Failed to update product');
            }
        } catch (error) {
            // Handle errors (network issues, backend errors)
            console.error('Error updating product:', error);
        }
    };

    // Add new option function
    const addNewOption = () => {
        if (newOption.trim()) {
            setSelectedProduct(prevState => ({
                ...prevState,
                options: [...prevState.options, newOption],
            }));
            setNewOption(''); // Clear input field
            setIsAddingOption(false); // Hide input field
        }
    };


    // Function to move the image up
    const moveImageUp = (index) => {
        const updatedImages = [...selectedViewImgData];
        // Swap the current image with the previous one
        [updatedImages[index], updatedImages[index - 1]] = [updatedImages[index - 1], updatedImages[index]];
        setSelectedViewImgData(updatedImages); // Update the state with the new order
    };

    // Function to move the image down
    const moveImageDown = (index) => {
        const updatedImages = [...selectedViewImgData];
        // Swap the current image with the next one
        [updatedImages[index], updatedImages[index + 1]] = [updatedImages[index + 1], updatedImages[index]];
        setSelectedViewImgData(updatedImages); // Update the state with the new order
    };

    // Function to save the reordered images
    const saveReorderedImages = async () => {
        try {
            // console.log(selectedViewImgData,selectedProductID);
            const updatedProduct = { ...selectedProduct, images: selectedViewImgData };
            // console.log(updatedProduct)
            // console.log(selectedProduct)
            // Make API call to save the updated product images order
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/products/editimageorder`,
                { updatedProduct, selectedProductID }
            );

            // Update the selectedProduct state correctly using spread operator
            setSelectedProduct(prevState => ({
                ...prevState,  // Keep all previous state values
                images: selectedViewImgData,  // Update only the images
            }));

            alert('Images order saved successfully!');
            closeViewImgModal(); // Close the modal after saving
        } catch (error) {
            console.error('Error saving images order:', error);
            alert('Failed to save images order');
        }
    };


    return (
        <div className="flex w-full">
            {/* Sidebar with vertical tabs */}
            <div className="w-full p-4 bg-gray-50 rounded-lg shadow-lg overflow-y-auto">
                <h2 className="text-xl font-semibold mb-4 text-black">Edit/Delete Product</h2>
                {Object.keys(groupedProducts).map((category) => (
                    <div key={category} className="mb-4">
                        {/* Accordion Header */}
                        <div
                            className="flex justify-between items-center bg-gray-200 p-3 rounded-lg cursor-pointer hover:bg-gray-300"
                            onClick={() => {
                                const updatedGroup = { ...groupedProducts };
                                updatedGroup[category].isOpen = !updatedGroup[category].isOpen;
                                setGroupedProducts(updatedGroup);
                            }}
                        >
                            <h3 className="text-lg font-semibold text-black">{category}</h3>
                            <span className="text-sm font-medium text-gray-600">
                                {groupedProducts[category]?.isOpen ? "▲" : "▼"}
                            </span>
                        </div>

                        {/* Accordion Content */}
                        {groupedProducts[category]?.isOpen && (
                            <ul className="mt-2">
                                {groupedProducts[category].map((product) => (
                                    <li key={product._id} className="mb-2">
                                        <div className="grid grid-cols-3 gap-4 bg-white p-3 rounded-lg shadow-md hover:bg-pink-50">
                                            <span className="font-medium text-black">{product.name}</span>
                                            <button
                                                onClick={() => handleViewClick(product)}
                                                className="text-sm hover:underline hover:text-pink-800"
                                            >
                                                View
                                            </button>
                                            <button
                                                onClick={() => handleViewImgClick(product)}
                                                className="text-sm hover:underline hover:text-pink-800"
                                            >
                                                Edit Images
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>

            {/* Modal for viewing review details */}
            {isModalOpen && selectedProduct &&
                (
                    <div
                        id="modal-overlay"
                        className="fixed inset-0 p-6 bg-black bg-opacity-40 flex items-center justify-center max-h-[100vh] z-50"
                        onClick={handleOutsideClick}
                    >
                        <div className="bg-white w-3/4 md:w-1/2 lg:w-1/3 p-6 rounded-lg shadow-lg relative overflow-y-scroll max-h-[85vh] scroll-hide">

                            <button
                                onClick={closeModal}
                                className="absolute top-2 right-2 text-white hover:text-[var(--secondary)] text-xl flex justify-center items-center"
                            >
                                &times;
                            </button>

                            <h3 className="text-2xl text-black font-semibold mb-4">{selectedProduct.name}</h3>


                            <div className="flex flex-col space-y-3 p-4 border rounded-lg shadow-md bg-white">
                                {/* Product Name */}
                                {/* <h2 className="text-xl font-bold text-gray-800">{selectedProduct.name}</h2> */}

                                {/* Category and Subcategory */}
                                <p className="text-gray-600">
                                    <span className="font-semibold">Category:</span> {selectedProduct.category} - {selectedProduct.subCategory}
                                </p>

                                {/* Product Code */}
                                <p className="text-gray-600">
                                    <span className="font-semibold">Code:</span> {selectedProduct.code}
                                </p>

                                {/* Pricing Information */}
                                <div className="text-gray-600">


                                    <span className="font-semibold">Pricing:</span>
                                    <ul className="space-y-1 mt-1">
                                        {/* Dynamically render pricing sections based on available keys */}
                                        {Object.entries(selectedProduct?.customPrices || {}).map(([type, prices]) => (
                                            <React.Fragment key={type}>
                                                <li className="font-medium mt-2">{type.replace(/([A-Z])/g, ' $1').trim()}:</li>
                                                {prices?.map((option, index) => (
                                                    <li key={index} className="text-sm flex items-center space-x-2 justify-between">
                                                        <span>{option.metal}:</span>
                                                        {/* Editable input for price */}
                                                        <input
                                                            type="text"
                                                            className="border rounded p-1 w-24"
                                                            value={option.price}
                                                            onChange={(e) => handleCustomPriceChange(e, type, index)}
                                                        />
                                                    </li>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </div>

                                {/* Weight Information */}
                                <p className="text-gray-600 flex justify-between items-center">
                                    <span className="font-semibold">Gold Weight:</span>
                                    <div>
                                        <input className='text-right'
                                            value={goldWeight}
                                            onChange={(e) => { setGoldWeight(e.target.value) }}
                                        />

                                        {/* {selectedProduct.goldWeight} */}
                                        g
                                    </div>
                                </p>
                                <p className="text-gray-600 flex justify-between items-center">
                                    <span className="font-semibold">Diamond Weight:</span>
                                    <div>
                                        <input className='text-right'
                                            value={diamondWeight}
                                            onChange={(e) => { setDiamondWeight(e.target.value) }}
                                        />
                                        {/* {selectedProduct.diamondWeight} */}
                                        ct
                                    </div>
                                </p>

                                {/* Available Options */}
                                <div>
                                    <p className="text-gray-600 flex justify-between items-center">
                                        <span className="font-semibold">Available Cut:</span>
                                        {selectedProduct.options.length > 0 ? (
                                            selectedProduct.options.map((option, index) => (
                                                <span key={index} className="flex items-center space-x-2">
                                                    <span>{option}</span>
                                                    <button
                                                        onClick={() => deleteProductOption(option)}
                                                        className="text-red-700 "
                                                    >
                                                        x
                                                    </button>
                                                </span>
                                            ))
                                        ) : (
                                            <span>No options available</span>
                                        )}
                                    </p>

                                    {/* Add new option input toggle */}
                                    <div>
                                        {isAddingOption ? (
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="text"
                                                    value={newOption}
                                                    onChange={(e) => setNewOption(e.target.value)}
                                                    placeholder="New option"
                                                    className="border border-gray-300 p-1 text-black"
                                                />
                                                <button onClick={addNewOption} className="bg-blue-500 text-white px-2 py-1 rounded">
                                                    Add
                                                </button>
                                                <button
                                                    onClick={() => setIsAddingOption(false)}
                                                    className="text-gray-500 hover:text-gray-700"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : (
                                            <button
                                                onClick={() => setIsAddingOption(true)}
                                                className="text-blue-500 hover:text-blue-700"
                                            >
                                                + new cut
                                            </button>
                                        )}
                                    </div>
                                </div>

                                {/* Action Buttons */}
                                <div className="flex justify-between space-x-3 mt-4">

                                    <button
                                        onClick={() => DeleteProduct()}
                                        className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        onClick={() => handleSave()}
                                        className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }


            {viewImgModalOpen && (
                <div
                    className="fixed top-0 inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50"
                    onClick={closeViewImgModal}
                >
                    <div
                        className="bg-white w-3/4 md:w-1/2 lg:w-1/3 p-6 rounded-lg shadow-lg overflow-y-auto max-h-[80vh] relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Close Button */}
                        <button
                            onClick={closeViewImgModal}
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        >
                            &times;
                        </button>

                        {/* Modal Heading */}
                        <h3 className="text-2xl text-black font-semibold mb-4">{selectedName}</h3>
                        <h2 className="text-black font-semibold mb-4">Re-Order Images</h2>

                        {/* Image List */}
                        <ul className="space-y-4">
                            {selectedViewImgData.map((image, index) => (
                                <li key={index} className="flex items-center justify-between bg-gray-100 p-3 rounded-lg shadow-sm">
                                    <div className="flex items-center space-x-3">
                                        {/* Display Image Number */}
                                        <span className="text-gray-700 font-semibold">{index + 1}</span>
                                        <img
                                            src={image.url}
                                            alt={`Image ${index + 1}`}
                                            className="w-20 h-20 object-cover rounded-lg"
                                        />
                                    </div>

                                    {/* Reordering Controls */}
                                    <div className="flex items-center space-x-2">
                                        {/* Move Image Up Button */}
                                        {index > 0 && (
                                            <button
                                                onClick={() => moveImageUp(index)}
                                                className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                                            >
                                                ↑
                                            </button>
                                        )}

                                        {/* Move Image Down Button */}
                                        {index < selectedViewImgData.length - 1 && (
                                            <button
                                                onClick={() => moveImageDown(index)}
                                                className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600"
                                            >
                                                ↓
                                            </button>
                                        )}

                                        {/* Delete Button */}
                                        <button
                                            onClick={() => handleViewImgDelete(index)}
                                            className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        {/* Save Changes Button */}
                        <div className="mt-4">
                            <button
                                onClick={saveReorderedImages}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                            >
                                Save Order
                            </button>
                        </div>

                        {/* Add New Image */}
                        <div className="mt-8">
                            <h2 className="text-black font-semibold mb-4">Add New Image</h2>

                            <div>
                                <input
                                    onChange={e => setFile1(e.target.files[0])}
                                    type="file"
                                    accept="image/*"
                                    className="input formInput"
                                    name="image"
                                />
                                {file1 && (
                                    <button onClick={(e) => uploadPhoto(e)}>
                                        upload
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>

    )
}

export default AddEditProds