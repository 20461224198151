import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';

const ProductCategoryManager = () => {
    const [newCategory, setNewCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [productCategory, setProductCategory] = useState()

    const addCategory = async () => {
        try {
            if (newCategory) {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/addcategory`, {
                    category: newCategory,
                });
                // console.log(response.data);
                setCategories((prev) => [...prev, response.data]) //push category to categoriesss
                setNewCategory('')
            }
        } catch (error) {
            console.error('There was an error posting the love letter!', error);
        }
    };
    useEffect(() => {

        const getcategories = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getcategory`)

                // console.log(data.data);
                setCategories(data.data);
            }
            catch (error) { console.log('There was an error getting the Categories', error); }
        }

        getcategories();

    }, [])

    return (
        <div>
            <div className="font-subheading text-3xl mb-6">Add Product Category</div>

            <div className="flex gap-[5%] w-full">
                {/* Add New Category */}
                <div className="w-full">
                    <div>Add Product Category</div>
                    <input
                        className="input formInput"
                        type="text"
                        placeholder="category"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <button onClick={addCategory} className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                        Add Category
                    </button>
                </div>

                {/* Existing Categories */}
                <div className="w-full">
                    <div>Existing Categories</div>
                    <select
                        className="input formInput"
                        value={productCategory}
                        onChange={(e) => setProductCategory(e.target.value)}
                    >
                        <option value="">Select a category</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category.category}>
                                {category.category}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default ProductCategoryManager;
