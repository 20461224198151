// import { set } from 'mongoose';
import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import './Admin.css'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AddEditProds from './AddEditProds';
import LogoutIcon from '@mui/icons-material/Logout';
import Sidebar from './Sidebar';
import LoveLetterSection from './LoveLetterSection';
import ProductCategoryManager from "./ProductCategoryManager";
import ProductSubCategoryManager from "./ProductSubCategoryManager"; // Adjust path as needed


const AdmindashBoard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeSection, setActiveSection] = useState('section1');
  const [activeSubToggle, setActiveSubToggle] = useState('subToggle1');


  const handleNavigate = (queryString) => {
    const section = new URLSearchParams(queryString).get('section');
    setActiveSection(section);
    navigate(queryString); // Update URL in the browser
  };

  const handleToggle = (section) => {
    setActiveSection(section);
  };
  // Sync state with the section value from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get('section') || 'section1';
    console.log(section)
    if (section) {
      setActiveSection(section);
    }
  }, [location.search]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])








  // const [categories, setCategories] = useState([]);







  const [categories, setCategories] = useState([]);
  const [productCategory, setProductCategory] = useState();
  const [newSubCategory, setNewSubCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [productSubCategory, setProductSubCategory] = useState('');
  useEffect(() => {

    const getcategories = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getcategory`)

        // console.log(data.data);
        setCategories(data.data);
      }
      catch (error) { console.log('There was an error getting the Categories', error); }
    }

    getcategories();

  }, [])

  useEffect(() => {
    const category = categories.filter(c => c.category === productCategory)
    // console.log(category)
    const subcategories = category[0]?.subcategories
    // console.log(subcategories)
    if (subcategories) {
      setSubCategories(subcategories)
    }
  }, [productCategory, categories])




  const [ringOptions, setRingOptions] = useState(""); // Stores "Yes" or "No" for the main question
  const [selectedOptions, setSelectedOptions] = useState([]); // Stores selected options

  const ringoptions = ['emerald', 'oval', 'round'];

  // Function to handle checkbox changes
  const handleCheckboxChange = (option) => {
    setSelectedOptions(prevState =>
      prevState.includes(option)
        ? prevState.filter(opt => opt !== option) // Remove if already selected
        : [...prevState, option] // Add if not selected
    );
  };

  const [halfBand, setHalfband] = useState(false)









  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();
  const [file4, setFile4] = useState();


  const [imageBoxCount, setImageBoxCount] = useState(1);

  const addimageboxes = () => {
    if (imageBoxCount < 4) {
      setImageBoxCount(imageBoxCount + 1);
    }

  };

  const [haloOptions, setHaloOptions] = useState(false)
  // console.log(haloOptions)


  const [customPrices, setcustomPrices] = useState([
    // { metal: '', price: '' },
    { metal: 'Silver', price: '' },
    { metal: '14kt', price: '' },
    { metal: '18kt', price: '' },
    { metal: '22kt', price: '' }
  ]);
  // useless state, will delete after everything ^^^^^^^^^^^^ok
  // yei neeche walei yr dikat hoki what she want bronze jewekkery
  // she can add a new row?


  const [customPricesRings, setcustomPricesRings] = useState({
    HalfBand: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ],
    FullBand: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ]
  });

  const [customPricesNo, setcustomPricesNo] = useState(
    {
      NoOption: [
        { metal: 'Silver', price: '' },
        { metal: '14kt', price: '' },
        { metal: '18kt', price: '' },
        { metal: '22kt', price: '' }
      ]
    }
  );

  const [customPricesEarrings, setcustomPricesEarrings] = useState({
    Halo: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ],
    withoutHalo: [
      { metal: 'Silver', price: '' },
      { metal: '14kt', price: '' },
      { metal: '18kt', price: '' },
      { metal: '22kt', price: '' }
    ]
  });


  const handleFieldChange = (setData, bandType, index, field, value) => {
    setData(prevState => ({
      ...prevState,
      [bandType]: prevState[bandType].map((entry, i) =>
        i === index ? { ...entry, [field]: value } : entry
      )
    }));
  };

  const addEntry = (setData, bandType) => {
    setData(prevState => ({
      ...prevState,
      [bandType]: [...prevState[bandType], { metal: '', price: '' }]
    }));
  };

  const removeEntry = (setData, bandType, index) => {
    setData(prevState => ({
      ...prevState,
      [bandType]: prevState[bandType].filter((_, i) => i !== index)
    }));
  };


  useEffect(() => {
    // console.log(customPricesRings, 'customPrices')
  }, [customPricesRings])


  const [aboutProduct, setAboutProduct] = useState('');
  const [goldWeight, setgoldWeight] = useState('');
  const [diamondWeight, setdiamondWeight] = useState('');
  const [hidePricing, setHidePricing] = useState(true);

  const toggleHidePricing = () => {
    setHidePricing((prev) => !prev); // Toggle between true and false
  };

  const submitProduct = async (e) => {
    e.preventDefault();


    const formData = new FormData();

    formData.append("image", file1);
    formData.append("image", file2);
    formData.append("image", file3);
    formData.append("image", file4);
    formData.append("category", productCategory);
    formData.append("subCategory", productSubCategory);
    formData.append("name", productName);
    formData.append("code", productCode);
    formData.append("about", aboutProduct);
    formData.append("goldWeight", goldWeight);
    formData.append("diamondWeight", diamondWeight);
    formData.append("Options", JSON.stringify(selectedOptions));
    if (productCategory === "Rings") {
      if (halfBand === 'Yes') {
        formData.append("CustomPrices", JSON.stringify(customPricesRings));
      } else {
        formData.append("CustomPrices", JSON.stringify(customPricesNo));
      }
    }

    if (productCategory === "Earrings" || productCategory === "Neckpieces") {
      if (haloOptions === 'Yes') {
        formData.append("CustomPrices", JSON.stringify(customPricesEarrings));
      } else {
        formData.append("CustomPrices", JSON.stringify(customPricesNo));
      }
    }

    if (productCategory === "Wristwear") {
      formData.append("CustomPrices", JSON.stringify(customPricesNo));
    }

    formData.append("showPrices", hidePricing);


    if (productCategory && productSubCategory && productName && productCode && goldWeight && diamondWeight) {
      console.log('submiitted')
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/products/addproduct`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        console.log(response.data)
        if (response.status === 201 && response.data.success) {
          // Success message
          alert("Product added successfully!");
          window.location.reload();
        } else {
          // Fallback message for unexpected success responses
          alert("Product addition was not successful. Please try again.");
        }
      } catch (err) {
        // Handle error responses
        if (err.response) {
          // Backend provided an error response
          const errorMessage = err.response.data.error || "An error occurred while adding the product.";
          alert(`Error: ${errorMessage}`);
        } else if (err.request) {
          // No response was received from the server
          alert("No response from the server. Please check your internet connection.");
        } else {
          // Other errors
          alert(`Unexpected error: ${err.message}`);
        }
        console.error(err);
      }

    } else { window.alert('please fill all the details') }

  };






  console.log(productCategory)

  const content = {
    section1: (
      <LoveLetterSection

      />
    ),
    section2: (
      <ProductCategoryManager />
    ),
    section3: (
      <ProductSubCategoryManager />
    ),
    section4: (<div>

      <div className='font-subheading text-3xl mb-6'>add a product </div>
      <div className='flex w-full flex-col !gap-8'>

        <div>
          <div>Select a Category</div>
          <select className='input formInput'
            value={productCategory}
            onChange={(e) => { setProductCategory(e.target.value) }}
          >
            <option value=''></option>
            {categories && categories.map(category =>
              <option value={category.category}>{category.category}</option>
            )}

          </select>
        </div>

        <div className='w-full'>
          <div>Select Product Sub-Category</div>
          <select className='input formInput'
            value={productSubCategory}
            onChange={(e) => { setProductSubCategory(e.target.value) }}
          >
            <option value=''></option>
            {subCategories && subCategories.map(c =>
              <option value={c}>{c}</option>
            )}

          </select>
        </div>




        {productSubCategory && <>
          <div className='formGrid mt-8'>

            <div className='w-full'>
              <div>Product Name</div>
              <input className='input formInput'
                type="text"
                value={productName}
                onChange={(e) => { setProductName(e.target.value) }}
              />
            </div>

            <div className='w-full'>
              <div>Product Code</div>
              <input className='input formInput'
                type="text"
                value={productCode}
                onChange={(e) => { setProductCode(e.target.value) }}
              />
            </div>

          </div>

          <div>

            <div>Add Images</div>

            <div className='formGrid'>

              <div>
                <input
                  onChange={
                    e => setFile1(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />
              </div>

              {imageBoxCount > 1 ? <div>

                <input
                  onChange={
                    e => setFile2(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />

              </div> : <></>}

              {imageBoxCount > 2 ? <div>

                <input
                  onChange={
                    e => setFile3(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />

              </div> : <></>}

              {imageBoxCount > 3 ? <div>

                <input
                  onChange={
                    e => setFile4(e.target.files[0])
                  }
                  type="file"
                  accept="image/*"
                  className='input formInput'
                  name='image'
                />

              </div> : <></>}

            </div>

            <br />
            <button onClick={() => { addimageboxes() }}>add more images</button>

          </div>
          {/* use this state for radio checkbox */}
          {/* const[ringOptions,setRingOptions]=useState(false); */}

          {/* if someone clicks yes make  */}


          {/* ringoptions  ->map this array for options, when product is rings */}



          <div>
            <button onClick={toggleHidePricing}>
              {hidePricing ? "Hide Pricing" : "Show Pricing"}
            </button>
          </div>

          <>
            {(productCategory === 'Rings' || productCategory === 'Earrings') ?
              <div>
                {/* radio insert here, Does your product have any options?
            options-yes or no */}

                <div>Does your product have any options?</div>

                <div className='flex gap-4 justify-evenly items-center'>
                  <div>
                    <input
                      type="radio"
                      name='ringOptions'
                      id='yes'
                      value="Yes"
                      onClick={(e) => setRingOptions(e.target.value)}
                      checked={ringOptions === "Yes"}
                    />
                    <label htmlFor="yes" className='ml-3 cursor-pointer'>Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name='ringOptions'
                      id='no'
                      value="No"
                      onClick={(e) => setRingOptions(e.target.value)}
                      checked={ringOptions === "No"}
                    />
                    <label htmlFor="no" className='ml-3 cursor-pointer'>No</label>
                  </div>
                </div>

                {ringOptions === "Yes" && (
                  <div>
                    <div>Select Product Options:</div>
                    {ringoptions.map(option => (
                      <div key={option} className="flex items-center">
                        <input
                          type="checkbox"
                          id={option}
                          value={option}
                          checked={selectedOptions.includes(option)}
                          onChange={() => handleCheckboxChange(option)}
                        />
                        <label htmlFor={option} className="ml-2 cursor-pointer">{option}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div >
              : <></>
            }


            {productCategory == 'Rings' ?
              <>
                <div>
                  <div>Does this Ring offer Half-Band or Full-Band Option?</div>

                  <div className='flex gap-4 justify-evenly items-center'>
                    <div>
                      <input
                        type="radio"
                        name='halfBand'
                        id='yes'
                        value="Yes"
                        onClick={(e) => setHalfband(e.target.value)}
                        checked={halfBand == "Yes"}
                      />
                      <label htmlFor="yes" className='ml-3 cursor-pointer'>Yes</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name='halfBand'
                        id='no'
                        value="No"
                        onClick={(e) => setHalfband(e.target.value)}
                        checked={halfBand === "No"}
                      />
                      <label htmlFor="no" className='ml-3 cursor-pointer'>No</label>
                    </div>
                  </div>


                </div >

                {halfBand === 'Yes' ? (
                  <>
                    <div className='w-full'>
                      <div>Add Half-Band Metals & Prices</div>
                      {customPricesRings.HalfBand?.map((entry, index) => (
                        <div key={index} className="flex gap-2 mb-2">
                          <input
                            type="text"
                            value={entry.metal}
                            onChange={(e) => handleFieldChange(setcustomPricesRings, 'HalfBand', index, 'metal', e.target.value)}
                            placeholder="Metal"
                            className="input formInput"
                          />
                          <input
                            type="text"
                            value={entry.price}
                            onChange={(e) => handleFieldChange(setcustomPricesRings, 'HalfBand', index, 'price', e.target.value)}
                            placeholder="Price"
                            className="input formInput"
                          />
                          <button onClick={() => removeEntry(setcustomPricesRings, 'HalfBand', index)} color="red">Remove</button>
                        </div>
                      ))}
                      <button onClick={() => addEntry(setcustomPricesRings, 'HalfBand')} color="green">+ Add Entry</button>
                    </div>

                    <div className='w-full'>
                      <div>Add Full-Band Metals & Prices</div>
                      {customPricesRings.FullBand?.map((entry, index) => (
                        <div key={index} className="flex gap-2 mb-2">
                          <input
                            type="text"
                            value={entry.metal}
                            onChange={(e) => handleFieldChange(setcustomPricesRings, 'FullBand', index, 'metal', e.target.value)}
                            placeholder="Metal"
                            className="input formInput"
                          />
                          <input
                            type="text"
                            value={entry.price}
                            onChange={(e) => handleFieldChange(setcustomPricesRings, 'FullBand', index, 'price', e.target.value)}
                            placeholder="Price"
                            className="input formInput"
                          />
                          <button onClick={() => removeEntry(setcustomPricesRings, 'FullBand', index)} color="red">Remove</button>
                        </div>
                      ))}
                      <button onClick={() => addEntry(setcustomPricesRings, 'FullBand')} color="green">+ Add Entry</button>
                    </div>
                  </>
                ) :

                  <div className='w-full'>
                    <div>Add Ring Metals & Prices</div>
                    {customPricesNo.NoOption?.map((entry, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          value={entry.metal}
                          onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'metal', e.target.value)}
                          placeholder="Metal"
                          className="input formInput"
                        />
                        <input
                          type="text"
                          value={entry.price}
                          onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'price', e.target.value)}
                          placeholder="Price"
                          className="input formInput"
                        />
                        <button onClick={() => removeEntry(setcustomPricesNo, 'NoOption', index)} color="red">Remove</button>
                      </div>
                    ))}
                    <button onClick={() => addEntry(setcustomPricesNo, 'NoOption')} color="green">+ Add Entry</button>
                  </div>
                }

              </>
              : <></>
            }
            {(productCategory === 'Earrings' || productCategory === 'Neckpieces') ?
              <>


                <div>Does the Product offer Halo/Non-halo Options?</div>

                <div className='flex gap-4 justify-evenly items-center'>
                  <div>
                    <input
                      type="radio"
                      name='haloOptions'
                      id='yes'
                      value="Yes"
                      onClick={(e) => setHaloOptions(e.target.value)}
                      checked={haloOptions === "Yes"}
                    />
                    <label htmlFor="yes" className='ml-3 cursor-pointer'>Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name='haloOptions'
                      id='no'
                      value="No"
                      onClick={(e) => setHaloOptions(e.target.value)}
                      checked={haloOptions === "No"}
                    />
                    <label htmlFor="no" className='ml-3 cursor-pointer'>No</label>
                  </div>
                </div>

                {haloOptions === 'Yes' ? (
                  <>
                    <div className='w-full'>
                      <div>Add Halo Metals & Prices</div>
                      {customPricesEarrings.Halo?.map((entry, index) => (
                        <div key={index} className="flex gap-2 mb-2">
                          <input
                            type="text"
                            value={entry.metal}
                            onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'Halo', index, 'metal', e.target.value)}
                            placeholder="Metal"
                            className="input formInput"
                          />
                          <input
                            type="text"
                            value={entry.price}
                            onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'Halo', index, 'price', e.target.value)}
                            placeholder="Price"
                            className="input formInput"
                          />
                          <button onClick={() => removeEntry(setcustomPricesEarrings, 'Halo', index)} color="red">Remove</button>
                        </div>
                      ))}
                      <button onClick={() => addEntry(setcustomPricesEarrings, 'Halo')} color="green">+ Add Entry</button>
                    </div>

                    <div className='w-full'>
                      <div>Add withoutHalo Metals & Prices</div>
                      {customPricesEarrings.withoutHalo?.map((entry, index) => (
                        <div key={index} className="flex gap-2 mb-2">
                          <input
                            type="text"
                            value={entry.metal}
                            onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'withoutHalo', index, 'metal', e.target.value)}
                            placeholder="Metal"
                            className="input formInput"
                          />
                          <input
                            type="text"
                            value={entry.price}
                            onChange={(e) => handleFieldChange(setcustomPricesEarrings, 'withoutHalo', index, 'price', e.target.value)}
                            placeholder="Price"
                            className="input formInput"
                          />
                          <button onClick={() => removeEntry(setcustomPricesEarrings, 'withoutHalo', index)} color="red">Remove</button>
                        </div>
                      ))}
                      <button onClick={() => addEntry(setcustomPricesEarrings, 'withoutHalo')} color="green">+ Add Entry</button>
                    </div>
                  </>
                ) :

                  <div className='w-full'>
                    <div>Add Metals & Prices</div>
                    {customPricesNo.NoOption?.map((entry, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          value={entry.metal}
                          onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'metal', e.target.value)}
                          placeholder="Metal"
                          className="input formInput"
                        />
                        <input
                          type="text"
                          value={entry.price}
                          onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'price', e.target.value)}
                          placeholder="Price"
                          className="input formInput"
                        />
                        <button onClick={() => removeEntry(setcustomPricesNo, 'NoOption', index)} color="red">Remove</button>
                      </div>
                    ))}
                    <button onClick={() => addEntry(setcustomPricesNo, 'NoOption')} color="green">+ Add Entry</button>
                  </div>
                }
              </ >
              : <></>
            }



            {productCategory === 'Wristwear' &&
              <div className='w-full'>
                <div>Add Wristwear Metals & Prices</div>
                {customPricesNo.NoOption?.map((entry, index) => (
                  <div key={index} className="flex gap-2 mb-2">
                    <input
                      type="text"
                      value={entry.metal}
                      onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'metal', e.target.value)}
                      placeholder="Metal"
                      className="input formInput"
                    />
                    <input
                      type="text"
                      value={entry.price}
                      onChange={(e) => handleFieldChange(setcustomPricesNo, 'NoOption', index, 'price', e.target.value)}
                      placeholder="Price"
                      className="input formInput"
                    />
                    <button onClick={() => removeEntry(setcustomPricesNo, 'NoOption', index)} color="red">Remove</button>
                  </div>
                ))}
                <button onClick={() => addEntry(setcustomPricesNo, 'NoOption')} color="green">+ Add Entry</button>
              </div>
            }
          </>
          {/* {hidePricing ? ()
            : (<></>)} */}








          <div className='w-full'>
            <div>About the Product</div>
            <textarea className='input formInput'
              type="text"
              value={aboutProduct}
              onChange={(e) => { setAboutProduct(e.target.value) }}
            />
          </div>

          <div className='formGrid'>
            <div className='w-full'>
              <div>Product Breakdown - Estimated Gold Weight (g) :</div>
              <input className='input formInput'
                type="number"
                value={goldWeight}
                onChange={(e) => { setgoldWeight(e.target.value) }}
              />
            </div>
            <div className='w-full'>
              <div>Estimated Diamond Weight (cttw) :</div>
              <input className='input formInput'
                type="number"
                value={diamondWeight}
                onChange={(e) => { setdiamondWeight(e.target.value) }}
              />
            </div>
          </div>





          <button onClick={submitProduct}>Add product</button>
        </>}
      </div>
    </div >),
    section5: (<div>
      <AddEditProds />
    </div>)
  };





  const logout = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logout`);
      if (response.data) {
        window.location.reload();
        // navigate('/');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }

  return (
    <div className='px-0 py-8 pb-0 flex flex-col gap-2 max-h-full'>

      <div className='flex justify-between items-center px-0'>
        <div></div>
        <Link to='/'><div className='font-heading text-[48px]'>DASHBOARD</div></Link>
        <div className='cursor-pointer flex gap-2 items-center'
          onClick={() => logout()}
        >
          <div>Logout</div>
          <LogoutIcon />
        </div>
      </div>

      <div className="flex h-full">
        {/* Sidebar (20% width) */}

        <Sidebar activeSection={activeSection} onNavigate={handleNavigate} />
        {/* Main Content (80% width) */}
        <div className="w-4/5 p-6 border-[6px] overflow-y-scroll h-full max-h-[calc(100vh-116px)] min-h-[calc(100vh-116px)] text-white text-left" style={{ borderColor: 'rgb(252, 231, 243)' }}>
          {content[activeSection]}
        </div>
      </div>


    </div>
  )
}

export default AdmindashBoard





