import React from 'react'
import './Checkout.css'
import { useShoppingCart } from '../../context/ShoppingCartContext'


const CheckoutItem = ({ item, products }) => {

    const { getItemPrice, getItemQuantity, formatIndianPrice } = useShoppingCart();
    const cartItem = products.find(i => i._id === item.id)
    if (cartItem == null) return null

    const quantity = getItemQuantity(item.id);
    const price = getItemPrice(item.id);

    return (
        <div className='CheckoutItem'>
            <div className='checkoutItemImg'>
                <img src={cartItem.imageURLs[0]?.url} alt="" />
            </div>

            <div className='checkoutItemInfo'>
                <div className='font-heading uppercase text-lg'>
                    {cartItem.name}
                </div>
                <div className='text-sm text-black'>
                    {cartItem.code}
                </div>
                
                <div className='text-sm text-black'>
                    Qty: {quantity}
                </div>

                <div className=''>
                    INR {formatIndianPrice(price)} /-  
                </div>
            </div>
        </div>
    )
}

export default CheckoutItem