import React from 'react';

const Sidebar = ({ activeSection, onNavigate }) => {
  return (
    <div className="w-1/5 bg-white p-4">
      <button
        className={`w-full py-2 px-4 rounded mb-2 flex  ${activeSection === 'section1' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
        onClick={() => onNavigate(`?section=section1`)}
      >
        Love Letter
      </button>
      <button
        className={`w-full py-2 px-4 rounded mb-2 text-left ${activeSection === 'section2' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
        onClick={() => onNavigate(`?section=section2`)}
      >
        Add Product Category
      </button>
      <button
        className={`w-full py-2 px-4 rounded mb-2 ${activeSection === 'section3' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
        onClick={() => onNavigate(`?section=section3`)}
      >
        Add Sub-Category
      </button>
      <button
        className={`w-full py-2 px-4 rounded mb-2 ${activeSection === 'section4' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
        onClick={() => onNavigate(`?section=section4`)}
      >
        Add Product
      </button>
      <button
        className={`w-full py-2 px-4 rounded mb-2 ${activeSection === 'section5' ? 'bg-pink-100' : 'hover:bg-pink-50'}`}
        onClick={() => onNavigate(`?section=section5`)}
      >
        Edit/Delete Product
      </button>
    </div>
  );
};

export default Sidebar;
