import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCategoryManager from "./ProductCategoryManager"; // Adjust the path as per your folder structure


const LoveLetterSection = () => {



    const [activeLetterSection, setActiveLetterSection] = useState('section1');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLoveletter, setSelectedLoveletter] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState('');

    // Close the modal and enable scrolling
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedLoveletter(null);
        document.body.classList.remove('overflow-hidden');
    };

    // Close modal on click outside
    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            closeModal();
        }
    };

    const [loveletters, setLoveletters] = useState([]);

    const postLoveLetter = async () => {

        console.log(name, message);

        try {
            if (name && message) {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/loveletters/post`, {
                    name: name,
                    message: message
                });
                console.log(response.data);
                setName('')
                setMessage('')
            }

        } catch (error) {
            console.error('There was an error posting the love letter!', error);
        }


    };

    useEffect(() => {
        if (activeLetterSection === 'section1') {

            const getloveletters = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/loveletters/get`)

                    setLoveletters((prevLoveletters) => [

                        ...response.data
                    ]);
                }
                catch (error) { console.log('There was an error getting the love letters!', error); }
            }

            getloveletters();
        }

    }, [activeLetterSection])

    const handleViewClick = (loveletter) => {
        // console.log(loveletter)
        setSelectedLoveletter(loveletter);
        setIsModalOpen(true);
        document.body.classList.add('overflow-hidden');
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        // Logic to save the edited message can be added here
        // console.log('Saving edited message:', editedMessage);
        setSelectedLoveletter({ ...selectedLoveletter, message: editedMessage });
        setIsEditing(false);
    };

     const DeleteLoveLetter = async () => {
        console.log(selectedLoveletter._id)
        const id = selectedLoveletter._id
        try {
          //  await axios.post('/deleteletter', { id });
    
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/loveletters/deleteletter`, {
            id
          });
    
          console.log('Response:', response.data);
        } catch (error) {
          console.error('Error posting ID to deleteletter:', error);
    
        }
    
      }

    const lovelettersHTML = {
        section1: (
            <div className='flex flex-col gap-6 w-full'>

                <div className='relative'>
                    <div className='font-subheading text-3xl mb-6'>add love letter</div>
                    <div>YOUR NAME *</div>
                    <input className='input formInput'
                        type="text"
                        placeholder='name'
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                    />

                </div>

                <div className='relative'>

                    <div>YOUR MESSAGE *</div>
                    <input className='input formInput'
                        type="text"
                        placeholder='message'
                        value={message}
                        onChange={(e) => { setMessage(e.target.value) }}
                    />

                </div>

                <button className='mt-4' onClick={() => { postLoveLetter() }}>SUBMIT</button>

            </div>
        ),
        section2: (
            <div className="flex w-full">
                {/* Sidebar with vertical tabs */}
                <div className="w-full p-4 bg-gray-50 rounded-lg shadow-lg overflow-y-auto">
                    <h2 className="text-xl font-semibold mb-4 text-black">All Love Letters</h2>
                    <ul>
                        {loveletters.map((review) => (
                            <li key={review._id} className="mb-2">
                                <div className="flex justify-between items-center bg-white p-3 rounded-lg shadow-md hover:bg-pink-50">
                                    <span className="font-medium text-black">{review.name}</span>
                                    <button
                                        onClick={() => handleViewClick(review)}
                                        className="text-sm text-pink-600 underline hover:text-pink-800"
                                    >
                                        View
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Modal for viewing review details */}
                {isModalOpen && selectedLoveletter &&
                    (
                        <div
                            id="modal-overlay"
                            className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50"
                            onClick={handleOutsideClick}
                        >
                            <div className="bg-white w-3/4 md:w-1/2 lg:w-1/3 p-6 rounded-lg shadow-lg relative">
                                {/* Close Button in Top Right Corner */}
                                <button
                                    onClick={closeModal}
                                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                                >
                                    &times; {/* Close icon */}
                                </button>

                                <h3 className="text-2xl text-black font-semibold mb-4">{selectedLoveletter.name}'s Review</h3>
                                {isEditing ? (
                                    <textarea
                                        value={editedMessage}
                                        onChange={(e) => setEditedMessage(e.target.value)}
                                        className="w-full h-24 p-2 border border-gray-300 rounded-lg mb-4"
                                    />
                                ) : (
                                    <p className="text-black mb-6">{selectedLoveletter.message}</p>
                                )}

                                {/* Action buttons */}
                                <div className="flex justify-between space-x-3">
                                    {isEditing ? (
                                        <button
                                            onClick={() => { handleSave() }}
                                            className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                                        >
                                            Save
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => { handleEdit() }}
                                            className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                                        >
                                            Edit
                                        </button>
                                    )}
                                    <button
                                        onClick={() => { DeleteLoveLetter() }}
                                        className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        onClick={() => alert('Archive function triggered')}
                                        className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600"
                                    >
                                        Archive
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        ),
    }

    return (
        <div className='w-full max-w-[600px] text-left text-white'>
            <div className="flex space-x-2 mb-4">
                <button
                    className={`py-2 px-4 rounded-lg ${activeLetterSection === 'section1' ? 'bg-pink-100 border border-pink-500' : 'hover:bg-pink-50'}`}
                    onClick={() => setActiveLetterSection('section1')}
                >
                    Add
                </button>
                <button
                    className={`py-2 px-4 rounded-lg ${activeLetterSection === 'section2' ? 'bg-pink-100 border border-pink-500' : 'hover:bg-pink-50'}`}
                    onClick={() => setActiveLetterSection('section2')}
                >
                    Remove
                </button>
            </div>
            {lovelettersHTML[activeLetterSection]}
        </div>
    );
};

export default LoveLetterSection;
