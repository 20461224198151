import React, { useEffect } from 'react'
import './OntheMove.css'
import { Link } from 'react-router-dom'
import one from '../../assets/shopcollection/otm1.png'
import two from '../../assets/shopcollection/otm2.png'
import three from '../../assets/shopcollection/otm3.png'
import four from '../../assets/shopcollection/otm4.png'
import five from '../../assets/shopcollection/otm5.png'
import ProductDisplay from '../Home/ProductDisplay/ProductDisplay'

const OntheMove = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='OntheMove'>

            <div className='px-5 sm:px-[72px]'>
                <button className='text-[var(--secondary)] bg-white'>SHOP COLLECTION</button>
                <div className='text-[10px] text-white text-left px-4 mt-4'><Link to='/' className='pinkHover'>HOME</Link> / <Link to='/shopcollection' className='pinkHover'>SHOP COLLECTION</Link> / ON THE MOVE</div>
            </div>

            <div className='onthemoveGrid'>

                <div className='h-[120px] my-8'>
                    <img src={one} className='object-contain h-full mx-auto' />
                </div>

                <div className='sm:text-[20px] text-white max-w-[500px] my-8'>
                    For many of us, especially women, multitasking feels almost instinctive. My mom, my friends, my cousins — they’re all hustlers, multitaskers and everyday champions. With so much going on, there’s often little time or energy to think about jewelry each day and decide what pairs with what.
                </div>

                <div className='w-full hidden sm:block'>
                    <img src={two} className='object-cover h-full w-full' />
                </div>

                <div className='w-full hidden sm:block'>
                    <img src={three} className='object-cover h-full w-full' />
                </div>

                <div className='w-full order-5 sm:order-4'>
                    <img src={four} className='object-cover w-full' />
                </div>

                <div className='sm:!hidden onthemoveGrid2'>
                    <div className='w-full'>
                        <img src={two} className='object-cover h-full w-full' />
                    </div>

                    <div className='w-full'>
                        <img src={three} className='object-cover h-full w-full' />
                    </div>
                </div>

                <div className='w-full sm:pl-8 order-4 sm:order-5 max-w-[360px] sm:max-w-unset mx-auto sm:mx-0'>
                    <div className='w-full hidden sm:block'>
                        <img src={five} className='object-contain w-full' />
                    </div>
                    <div className='text-white mt-8 mb-10 sm:mt-40 sm:mb-0 sm:text-[20px]'>
                        That’s what inspired our first campaign – On The Move. I asked myself what would I want as someone constantly on the move? I imagined something effortless. Something that works just as well for a busy day as it does for a night out. Something that feels easy to wear. Something that feels natural to put on.
                        <br /><br />
                        With our brand ethos centered around utility, we designed pieces that you can wear as is or stacked up for those special dinners you're rushing to from work. Through this campaign, Moheera aims to redefine jewelry as a lifestyle product. Instead of keeping those beautiful pieces locked away, why not wear them and enjoy them as often as you can?
                        <br /><br />
                        This is our way of making jewelry a part of your everyday life. <br />
                        <span className='text-[var(--primary)]'>A part of everyday you.</span>
                    </div>

                </div>

            </div>


            <button className='mb-0 mt-20 w-fit text-white mx-5 sm:mx-[72px]'>BUY COLLECTION</button>

            <ProductDisplay />
        </div>
    )
}

export default OntheMove